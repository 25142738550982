import React from 'react'
import styled from 'styled-components'
import { HeadProps } from 'gatsby'
import { Layout, LoginForm, HeadTags } from '@components'

const LoginPage = () => (
  <Layout simpleHeader>
    <Content>
      <LoginForm />
    </Content>
  </Layout>
)

export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/account" />
  </HeadTags>
)

const Content = styled.div`
  height: 100vh;
  @media only screen and (min-width: 940px) {
    height: calc(100vh - 300px);
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default LoginPage
